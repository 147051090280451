import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import generalFailedPC from '../../fetch/generalFailedPC';
import {UserDetail} from '../../../constants/propTypesDefinitions';
import {createFetchUserGenerateApiKey} from '../../../backend/apiCalls';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import withDataHOC from '../../dataProvider/withDataHOC';
import { GLOBAL_DATA } from '../../../constants/globalData';

/**
 * @dusan
 */

class GenerateApiKey extends React.PureComponent {
    static propTypes = {
        userDetail: UserDetail.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    render() {
        const {userDetail, [GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        return <FormModal
            openNode={
                    <Button icon="key">
                        <span className="ml-2"><Trans>Generovať API kľúč</Trans></span>
                    </Button>
            }
            values={{}}
            onFinishSuccessful={() => {
                reload([GLOBAL_DATA.USER_DETAILS]);  
            }}
            title={<Trans>Nový API kľúč</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Naozaj chcete vygenerovať nový API kľúč?</Trans>,
                createFetchUserGenerateApiKey(userDetail.id),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa vygenerovať API kľúč.`)}
        />;
    }
}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(GenerateApiKey);