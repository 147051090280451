import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import { QUERY_PARAMS} from '../../constants/navigation';
import { navigateToParametrized } from '../../lib/url';
import locationHOC from '../locationProvider/locationHOC';

/**
 * @dusan
 */

class ApiKey extends React.PureComponent {
    static propTypes = {
        apiKey: PropTypes.string,
    };

    render() {
        const {location, apiKey} = this.props;
        if(apiKey == null)
            return null;

        if(apiKey.indexOf('***') < 0)
            return apiKey;

        return <span>
            {apiKey}
            <Button
                className="ml-2"
                icon="eye"
                size="small"
                onClick={() => navigateToParametrized(location, null, {
                    [QUERY_PARAMS.SHOW_API_KEY]: 1
                })}
            />
        </span>;
    }
}

export default locationHOC(ApiKey);
