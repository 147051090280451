import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import Select from '../general/Select';
import {GLOBAL_DATA} from '../../constants/globalData';

/**
 * @fero
 */

class DepartmentsSelect extends React.PureComponent {
    static propTypes = {
        value: PropTypes.number,
    };

    render() {
        const { [GLOBAL_DATA.DEPARTMENTS]: departments, ...props } = this.props;
        return <Select
            {...props}
            options={departments.map(ch => {
                return {
                    label: ch.name,
                    value: ch.id,
                }
            })}
        />;
    }
}

export default withDataHOC([GLOBAL_DATA.DEPARTMENTS])(DepartmentsSelect);