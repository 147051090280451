import React from 'react';
import PropTypes from 'prop-types';

import Helmet from '../project/Helmet';
import AccountInfo from './userDetails/AccountInfo';
import AccountAddresses from '../account/AccountAddresses';
import EditUserDetail from './userDetails/EditUserDetail';
import RemoveUser from './userDetails/RemoveUser';
import withDataHOC from '../dataProvider/withDataHOC';
import {UserDetail, UserAddress, Attachments} from '../../constants/propTypesDefinitions';
import {GLOBAL_DATA} from '../../constants/globalData';
import {ROUTES} from '../../constants/navigation';
import {Trans, t} from '@lingui/macro'
import PageActionsLayout from '../general/PageActionsLayout';
import HistoryModal from '../history/HistoryModal';
import AttachmentsForDealerAndUser from '../project/AttachmentsForDealerAndUser';
import GenerateApiKey from './userDetails/GenerateApiKey';
import { RIGHTS } from '../../constants/Rights';

/**
 * @fero
 */

class UserDetailsPage extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.USER_DETAILS]: UserDetail.isRequired,
        [GLOBAL_DATA.USER_DETAILS_ADDRESSES]: PropTypes.arrayOf(UserAddress).isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    render() {
        const {
            [GLOBAL_DATA.USER_DETAILS]: userDetails,
            [GLOBAL_DATA.USER_DETAILS_ADDRESSES]: userAddresses,
            [GLOBAL_DATA.RELOAD_DATA]: reload
        } = this.props;
        const userId = userDetails.id;
        const {title = "", name = "", surname = "", customer = ""} = userDetails;
        const wholeName = (title != null ? title : "") + " " + name + " " + surname + " - " + customer
        let usedAddressIds = [];
        if(userDetails.invoice_address != null)
            usedAddressIds.push(userDetails.invoice_address.id);
            
        if(userDetails.delivery_address != null)
            usedAddressIds.push(userDetails.delivery_address.id);

        return <React.Fragment>
            <Helmet
                title={wholeName}
            />
            <div className="px-3 full-size-height full-size-width overflow-y-auto">
                <PageActionsLayout
                    back={{
                        to: ROUTES.CUSTOMERS,
                        title: <Trans>Organizácie</Trans>
                    }}
                    actions={[
                        {
                            node: <HistoryModal 
                                objectType="user" 
                                objectId={userDetails.id}
                                objectName={wholeName}
                            />
                        },
                        {
                            node: <GenerateApiKey userDetail={userDetails}/>,
                            rightsFrom: RIGHTS.SUPERVISOR,
                        },
                        {
                            node: <RemoveUser userDetail={userDetails}/>
                        },
                        {
                            node: <EditUserDetail userAddresses={userAddresses} userDetail={userDetails}/>
                        },
                    ]}
                />
                <h1 className="p-3 text-center">
                    {wholeName}
                </h1>
                <AccountInfo userDetails={userDetails}/>
                <AccountAddresses
                    onReload={() => reload([GLOBAL_DATA.USER_DETAILS_ADDRESSES])}
                    userAddresses={userAddresses}
                    userId={userId}
                    usedAddressIds={usedAddressIds}
                />
                <AttachmentsForDealerAndUser
                    customerId={userDetails.id_customer}
                    userId={userDetails.id}
                />
            </div>
        </React.Fragment>;
    }

}

export default withDataHOC([GLOBAL_DATA.USER_DETAILS, GLOBAL_DATA.USER_DETAILS_ADDRESSES, GLOBAL_DATA.RELOAD_DATA])(UserDetailsPage);