import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import generalFailedPC from '../../fetch/generalFailedPC';
import {UserDetail} from '../../../constants/propTypesDefinitions';
import {ROUTES, QUERY_PARAMS} from '../../../constants/navigation';
import {createFetchUserRemove} from '../../../backend/apiCalls';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import locationHOC from '../../locationProvider/locationHOC';
import withDataHOC from '../../dataProvider/withDataHOC';
import { GLOBAL_DATA } from '../../../constants/globalData';
import { navigateToParametrized } from '../../../lib/url';

/**
 * @fero
 */

class RemoveUser extends React.PureComponent {
    static propTypes = {
        userDetail: UserDetail.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    render() {
        const {location, userDetail, [GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        return <FormModal
            openNode={
                    <Button type="danger" icon="delete">
                        <span className="ml-2"><Trans>Odstrániť kontakt</Trans></span>
                    </Button>
            }
            values={{}}
            onFinishSuccessful={() => {
                reload([GLOBAL_DATA.USERS, GLOBAL_DATA.EMPLOYEES]);
                navigateToParametrized(location, ROUTES.CUSTOMERS, {[QUERY_PARAMS.USER_DETAILS_ID_USER]: undefined});
            }}
            title={<Trans>Odstránenie kontaktu</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Naozaj chcete vymazať kontakt?</Trans>,
                createFetchUserRemove(userDetail.id),
            )}
            Response={null}
            Failed={generalFailedPC(t`Kontakt sa nepodarilo odstrániť.`)}
        />;
    }
}

export default locationHOC(withDataHOC([GLOBAL_DATA.RELOAD_DATA])(RemoveUser));