import React from 'react';
import PropTypes from 'prop-types';
import {Trans, t} from '@lingui/macro';
/**
 * @fero
 */

class YesNo extends React.PureComponent {
    static propTypes = {
        value: PropTypes.oneOf([0, 1]).isRequired,
    };

    render() {
        const {value} = this.props;
        if (value != null) {
            return value === 1 ? <Trans>Áno</Trans> : <Trans>Nie</Trans>;
        } else {
            return null;
        }
    }

}

export default YesNo;