import React from 'react';
import PropTypes from 'prop-types';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class EmailLink extends React.PureComponent {
    static propTypes = {
        email: PropTypes.string,
    };

    render() {
        const {email} = this.props;
        if(email != null) {
            return <a className="text-dark" href={"mailto:" + email}>
                {email}
            </a>;
        } else {
            return <Trans>neuvedené</Trans>
        }
    }

}

export default EmailLink;