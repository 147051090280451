import React from 'react';
import Date from '../../general/Date';
import {UserDetail} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import PhoneLink from '../../general/PhoneLink';
import EmailLink from '../../general/EmailLink';
import CustomerLink from '../../general/CustomerLink';
import InfoAttributeGroup from '../../general/InfoAttributeGroup';
import Address from '../../general/Address';
import YesNo from '../../project/YesNo';
import ApiKey from '../../project/ApiKey';

/**
 * @fero
 */

class AccountInfo extends React.PureComponent {
    static propTypes = {
        userDetails: UserDetail.isRequired,
    };

    render() {
        const { userDetails } = this.props;
        const userInfo = userDetails != null ? userDetails : {};
        return <div className="d-flex justify-content-center pt-2 px-3 full-size-width">
            <InfoAttributeGroup
                attributes={[
                    {
                        title:<Trans>Titul:</Trans>, 
                        value: userInfo.title
                    },
                    {
                        title:<Trans>Meno:</Trans>, 
                        value: userInfo.name
                    },
                    {
                        title:<Trans>Priezvisko:</Trans>, 
                        value: userInfo.surname
                    },
                    {
                        title:<Trans>Email:</Trans>, 
                        value: <EmailLink email={userInfo.e_mail}/>
                    },
                    {
                        title:<Trans>Telefón:</Trans>, 
                        value: <PhoneLink phone={userInfo.phone}/>
                    },
                    {
                        title:<Trans>Spoločnosť:</Trans>,
                        value: userInfo.organization
                    },
                    {
                        title:<Trans>IČO:</Trans>, 
                        value: userInfo.ico
                    },
                    {
                        title:<Trans>IČ DPH:</Trans>, 
                        value: userInfo.ic_dph
                    },
                    {
                        title: <Trans>Fakturačná adresa:</Trans>,
                        value: <Address address={userInfo.invoice_address}/>
                    },
                    {
                        title: <Trans>Dodacia adresa:</Trans>,
                        value: <Address address={userInfo.delivery_address}/>
                    },
                ]}
            />
            <InfoAttributeGroup
                attributes={[
                    {
                        title:<Trans>Jazyk:</Trans>, 
                        value: userInfo.language
                    },
                    {
                        title:<Trans>Organizácia:</Trans>, 
                        value: <CustomerLink customerId={userInfo.id_customer} customerName={userInfo.customer}/>
                    },
                    {
                        title:<Trans>Rola:</Trans>, 
                        value: userInfo.role
                    },
                    {
                        title: <Trans>Nadriadený:</Trans>,
                        value: userInfo.supervisor
                    },
                    {
                        title: <Trans>Pracovná pozícia:</Trans>,
                        value: userInfo.position
                    },
                    {
                        title: <Trans>Odber noviniek:</Trans>,
                        value: <YesNo value={userInfo.newsletter}/>
                    },
                    {
                        title: <Trans>Licencia:</Trans>,
                        value: <Date dateString={userInfo.license_expiry}/>
                    },
                    {
                        title: <Trans>API kľúč:</Trans>,
                        value: <ApiKey apiKey={userInfo.api_key}/>
                    },
                    {
                        title: <Trans>Poznámky:</Trans>,
                        value: <pre>{userInfo.notices}</pre>
                    },
                    {
                        title:<Trans>Zaregistrovaný:</Trans>, 
                        value: <Date dateString={userInfo.registered_at}/>
                    },
                    {
                        title:<Trans>Posledné prihlásenie:</Trans>, 
                        value: <Date dateString={userInfo.last_access_at}/>
                    },
                    {
                        title:<Trans>Počet prihlásení:</Trans>, 
                        value: userInfo.access_count
                    },
                ]}
            />
        </div>;
    }

}

export default AccountInfo;