import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import Select from '../general/Select';
import {GLOBAL_DATA} from '../../constants/globalData';
import { createFetchDashboardVariables } from '../../backend/apiCalls';

/**
 * @dusan
 */

class DashboardItemsSelect extends React.PureComponent {
    static propTypes = {
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            variables: []
        }
    }

    fetchVariables = () => {
        const {[GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;
        fetchHandler(
            createFetchDashboardVariables(),
            {},
            (result) => {
                this.setState({variables: result});
            }
        );
    };

    componentDidMount() {
        this.fetchVariables();
    }

    render() {
        const {...props } = this.props;
        const {variables} = this.state;
        return <Select
            {...props}
            options={variables.map(v => {
                return {
                    label: v.label,
                    value: v.name,
                }
            })}
        />;
    }
}

export default withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(DashboardItemsSelect);